<template>
  <div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Reset Password v1 -->
        <b-card class="mb-0">
          <!-- logo -->
          <b-link class="brand-logo">
            <kultiver-logo />
            <h2 class="brand-text text-primary ml-1">
              Kultiver
            </h2>
          </b-link>
          <b-card-title class="mb-1">
            Nueva Contraseña 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Tu nueva contraseña debe ser diferente a tu contraseña anterior
          </b-card-text>
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- password -->
              <b-form-group
                label="Nueva contraseña"
                label-for="reset-password-new"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Contraseña"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirmar contraseña"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="confirmar contraseña"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                Confirmar
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Volver a login
            </b-link>
          </p>
        </b-card>
      <!-- /Reset Password v1 -->
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import KultiverLogo from '@core/layouts/components/Logo.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    KultiverLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Su contraseña ha sido actualizada con éxito',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.reset()
          this.$router.push({ name: 'login' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Su contraseña no ha sido actualizada',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
          this.reset()
        }
      })
    },
    reset() {
      this.cPassword = ''
      this.password = ''
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
